@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-Book.woff");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-Thin.otf");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-ExtraLight.otf");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-Light.otf");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-SemiBold.otf");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: 'Campton-Book';
  src: url("https://breakthruassets.blob.core.windows.net/assets/fonts/Campton-Bold.woff");
  font-weight: 700;
  font-display: fallback;
}

body {
  font-family: Campton-Book, sans-serif;
  transition: 0.2s ease background;
}
